import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import ReactDOM from 'react-dom'
import '../../node_modules/react-modal-video/css/modal-video.min.css';
import ModalVideo from 'react-modal-video';
import moment from "moment";
import { graphql } from "gatsby";
require('./formersuccess.css')


class Workshop extends React.Component {

  constructor() {
    super();
    this.state = {

      isOpen: false,
    };
    this.openModal = this.openModal.bind(this)
  }

  openModal() {
    this.setState({ isOpen: true })
  }


  render() {
    return (
      <div className="workshopMain" >
        <a href={this.props.workshop_url} target="_blank">
          <img src={this.props.img}
          />



          <div class="workshop-successInfo">

            <div className="col-sm-12  pad0">
              <div className="col-sm-12 flt-lft workshop-tt-fmt">
                <p style={{ textTransform: "Capitalize", display: "inline-block" }}>{this.props.location}</p>, {this.props.no_of_days == "1" ?
                  <p style={{ display: "inline-block" }}>{this.props.no_of_days} day</p>
                  : <p style={{ display: "inline-block" }}>{this.props.no_of_days} days</p>}, {this.props.date}</div>
              {/* <div className="col-sm-6 flt-lft workshop-place-plcd">{this.props.location}</div> */}

            </div>

            <div className="col-sm-12 flt-lft">
              <p className="desc-workshop-hds">{this.props.title}</p>
              <p className="desc-desc-wrkps">{this.props.description}</p>
            </div>

            {/* <div className="col-sm-12 flt-lft">
                          <p className="no-of-days">No of Days - {this.props.no_of_days}</p>
                        </div> */}
          </div>
        </a>
      </div>



    );
  }
}

export default Workshop;


