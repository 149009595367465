import React from "react";
import { Link } from "gatsby";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap.css";
import Layout from "../components/layout";
import FormerSuccess from "../components/farmersuccess";
import LetsChat from "../components/letsChat";
import Workshop from "../components/workshopComponet";
import moment from "moment";
import { graphql } from "gatsby";
import { Tabs } from 'antd';

import axios from "axios";
import SweetAlert from "sweetalert-react";
import { Modal, Button } from "antd";

const { TabPane } = Tabs;
require("../css/workshops.css");
require("../css/how-it-works.css");
require("../css/farmers.css");
require("../css/responsive.scss");

require("sweetalert/dist/sweetalert.css");

class FarmerMod extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      workshopData: [],
      editedworkshopData: [],
      pastWorkshop: [],
      upcomingWorkshop: [],

      showPopup: true,
      visible: false,
      loading: false,
      displayName: "none",
      displayEmail: "none",
      displayEmailInvalid: "none",
      displayPhone: "none",
      displayPhoneInvalid: "none",
      displayOrg: "none",
      displayQty: "none",
      name: "",
      phone: "",
      email: "",
      organisation: ""
    };
    this.handleWorkshopCard = this.handleWorkshopCard.bind(this)
  }


  componentDidMount() {

    var editedworkshopArr = [], getPastArr = [], getUpcomingArr = [];
    var threePastEvents, threeUpcomingEvents;
    var workshopData = this.props.data.allWordpressWpWorkshop.edges; // Fetch all Workshop data

    this.setState({ workshopData: workshopData }, () => {
      // console.log("this", this.state.workshopData);
      this.state.workshopData.map(e => {
        var date = e.node.acf.event_date;
        var convertDate = date.split("/").reverse().join("/");
        convertDate = new Date(convertDate);
        e.node.convertDate = convertDate; // adding convertDate to node obj
        e.node.displayDate = moment(convertDate).format("DD MMM YYYY");
        console.log("displayDate", e.node.displayDate)
        editedworkshopArr.push(e);

      })

      this.setState({ editedworkshopData: editedworkshopArr }, () => {
        this.state.editedworkshopData.map(e => {
          var cdate = e.node.convertDate;
          var newDate = new Date();
          var today = newDate.getFullYear() + "/" + newDate.getMonth() + "/" + newDate.getDate();
          var itIsToday = cdate.getFullYear() + "/" + cdate.getMonth() + "/" + cdate.getDate();
          // console.log("today", today, "itIsToday", itIsToday)

          if (cdate.getTime() < new Date().getTime() && itIsToday !== today) {
            // console.log("convertDate", e.node.convertDate)
            // console.log(e.node.convertDate.getTime(), new Date().getTime())
            getPastArr.push(e);
          }
          else {
            getUpcomingArr.push(e);
          }
        });

        // get 3 upcoming events
        var sortedUp = getUpcomingArr.sort((a, b) =>
          a.node.convertDate.valueOf() - b.node.convertDate.valueOf());
        // console.log("sortedUp", sortedUp);
        threeUpcomingEvents = sortedUp.slice(0, 3); // get 3 upcoming workshop
        // console.log("threeUpcomingEvents", threeUpcomingEvents);

        // get 3 past events
        var sortedDown = getPastArr.sort((a, b) =>
          a.node.convertDate.valueOf() - b.node.convertDate.valueOf());
        // console.log("sortedDown", sortedDown)
        threePastEvents = sortedDown.slice(Math.max(sortedDown.length - 3, 0)).reverse() // get 3 past workshop
        // console.log("threePastEvents", threePastEvents);

        this.setState({ pastWorkshop: threePastEvents, upcomingWorkshop: threeUpcomingEvents })
      });
    });
  }

  callback(key) {
    // console.log(key);
  }
  handleWorkshopCard(e) {
    this.setState({
      showPopup: true,
      visible: true
    })
  }

  closePopupOnstart = () => {
    this.setState({ showPopup: false })
  }

  handleOk() {
    var _this = this;
    var bodyFormData = new FormData();

    // var name = document.getElementById("name").value;
    var email = document.getElementById("email").value;
    var pattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    var phoneLength = document.getElementById("number").value.length;
    // var number = document.getElementById("number").value;
    //var quantity = document.getElementById("quantity").value;
    // var organisation = document.getElementById("org").value;
    var message = document.getElementById("comment").value;
    var prodName = document.getElementById("prodName").value;

    if (this.state.name == "") {
      this.validateName();
    } else if (this.state.phone == "" || phoneLength < 10) {
      this.validatePhone();
    } else if (this.state.email == "" || !pattern.test(email)) {
      this.validateEmail();
    } else if (this.state.organisation == "") {
      this.validateOrganisation();
    } else {
      bodyFormData.set("fullName", this.state.name);
      bodyFormData.set("email", this.state.email);
      bodyFormData.set("number", this.state.number);
      bodyFormData.set("organisation", this.state.organisation);
      bodyFormData.set("message", message);
      bodyFormData.set("ProductName", prodName);
      bodyFormData.set("formID", 1208);
      console.log(_this);
      axios
        .post(
          "https://dashboard.nativecircle.in/index.php/wp-json/contact-form-7/v1/contact-forms/1208/feedback",
          bodyFormData,
          {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          }
        )
        .then(response => {
          console.log(response.status);
          document.getElementById("form").reset();
          if (response.status == 200 || response.status == 201) {
            _this.setState({
              showAlert: true,
              loading: false
            });
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
  }

  handleCancel = () => {
    this.setState({ visible: false });
  };

  validateName() {
    var name = document.getElementById("name").value.replace(/[^a-zA-Z\s]/g, "");
    console.log("name", name)
    if (name == "" || name == undefined) {
      this.setState({ displayName: "block", name: "" });
    } else {
      this.setState({ displayName: "none", name: name });
    }
  }

  validateEmail() {
    var pattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    var email = document.getElementById("email").value;
    if (email == "" || email == undefined) {
      this.setState({ displayEmail: "block", email: "" });
    } else {
      this.setState({ displayEmail: "none", email: email });
      if (!pattern.test(email)) {
        this.setState({ displayEmailInvalid: "block" });
      } else {
        this.setState({ displayEmailInvalid: "none" });
      }
    }
  }

  validatePhone() {

    var phone = document.getElementById("number").value;
    var phonePattern = /^\d+$/.test(phone);
    // var phoneValue = document.getElementById("number").value.replace(/[^\d]/,'');

    if (phonePattern == true) {
      var phoneLength = document.getElementById("number").value.length;
      if (phone == "" || phone == undefined) {
        this.setState({ displayPhone: "block", phone: "" });
      } else {
        this.setState({ displayPhone: "none", phone: phone });
        // console.log("phonePattern.test(phone)", phonePattern.test(phone));
        if (phoneLength < 10) {
          this.setState({ displayPhoneInvalid: "block" });
        } else {
          this.setState({ displayPhoneInvalid: "none" });
        }
      }
    }
    else {
      this.setState({ displayPhone: "block", phone: "" });
    }

  }

  validateOrganisation() {
    var organisation = document.getElementById("org").value;
    if (organisation == "") {
      this.setState({ displayOrg: "block", organisation: "" });
    } else {
      this.setState({ displayOrg: "none", organisation: organisation });
    }
  }

  render() {
    const { pastWorkshop, upcomingWorkshop, visible, loading } = this.state;
    return (
      <Layout>
        <div className="farmerBanner">

          <div>
            {
              this.state.showPopup === true ?
                <div className="">
                  <div >

                    <SweetAlert
                      show={this.state.showAlert}
                      title="Thank you for contacting us!"
                      text="We will get in touch with you soon."
                      onConfirm={() => this.setState({ showAlert: false, visible: false })}
                    />
                    <Modal
                      visible={visible}
                      title="Enquires for farmers"
                      onOk={() => this.handleOk()}
                      onCancel={() => this.handleCancel()}
                      maskClosable={false}
                      footer={[
                        <Button key="back" onClick={() => this.handleCancel()}>
                          Cancel
            </Button>,
                        <Button
                          className="BusinessFormBtn"
                          key="submit"
                          type="primary"
                          loading={loading}
                          onClick={() => this.handleOk()}
                        >
                          Submit
            </Button>
                      ]}
                    >
                      <form action="javascript:void(0)" id="form">
                        <div className="row form-group">
                          <div className="col-md-12 col-xs-12 col-sm-12 ">
                            <label htmlFor="name" className="NC-formlabel">
                              Full name *
                </label>
                            <input
                              className="form-control NC-fullWidth formcontrolColorChange"
                              type="text"
                              id="name"
                              value={this.state.name}
                              required
                              onChange={() => this.validateName()}
                            />
                            <p
                              style={{
                                display: this.state.displayName,
                                color: "red",
                                marginTop: "10px"
                              }}
                            >
                              * This field is required
                </p>
                          </div>
                          <div className="col-md-12 col-xs-12 col-sm-12 padTop15">
                            <label htmlFor="number" className="NC-formlabel">
                              Mobile number *
                </label>
                            <input
                              className="form-control NC-fullWidth formcontrolColorChange"
                              type="text"
                              id="number"
                              maxlength="10"
                              size="10"
                              pattern='\d{10}'
                              // pattern="[0-9\/]*"
                              value={this.state.phone}
                              required
                              onChange={() => this.validatePhone()}
                            />
                            <p
                              style={{
                                display: this.state.displayPhone,
                                color: "red",
                                marginTop: "10px"
                              }}
                            >
                              * This field is required
                </p>
                            <p
                              style={{
                                display: this.state.displayPhoneInvalid,
                                color: "red",
                                marginTop: "10px"
                              }}
                            >
                              Please put 10 digit mobile number
                </p>
                          </div>
                          <div className="col-md-12 col-xs-12 col-sm-12 padTop15">
                            <label htmlFor="email" className="NC-formlabel">
                              Email ID *
                </label>
                            <input
                              className="form-control NC-fullWidth formcontrolColorChange"
                              type="email"
                              id="email"
                              // pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                              value={this.state.email}
                              required
                              onChange={() => this.validateEmail()}
                            />
                            <p
                              style={{
                                display: this.state.displayEmail,
                                color: "red",
                                marginTop: "10px"
                              }}
                            >
                              * This field is required
                    </p>

                            <p
                              style={{
                                display: this.state.displayEmailInvalid,
                                color: "red",
                                marginTop: "10px"
                              }}
                            >
                              The entered email id is not valid
                    </p>
                          </div>

                          {/*     <div className="col-md-12 col-xs-12 col-sm-12 padTop15">
                <label htmlFor="quantity" className="NC-formlabel">
                  Quantity
                </label>
                <input
                  className="form-control NC-fullWidth quantityfield formcontrolColorChange"
                  type="number"
                  id="quantity"
                  required
                  onInput={() => this.validateQuantity()}
                />
                <p
                  style={{
                    display: this.state.displayQty,
                    color: "red",
                    marginTop: "10px"
                  }}
                >
                  * This field is required
                </p>
              </div>
                */}

                          <div className="col-md-12 col-xs-12 col-sm-12 padTop15">
                            <label htmlFor="org" className="NC-formlabel">
                              Organization *
                </label>
                            <input
                              className="form-control NC-fullWidth formcontrolColorChange"
                              type="text"
                              id="org"
                              required
                              value={this.state.organisation}
                              onChange={() => this.validateOrganisation()}
                            />
                            <p
                              style={{
                                display: this.state.displayOrg,
                                color: "red",
                                marginTop: "10px"
                              }}
                            >
                              * This field is required
                </p>
                          </div>

                          <div className="col-md-12 col-xs-12 col-sm-12 padTop15">
                            <label htmlFor="comment" className="NC-formlabel">
                              Any queries / Comments
                </label>
                            <textarea
                              className="form-control formcontrolColorChange"
                              rows="5"
                              id="comment"
                            />
                          </div>

                          <label htmlFor="prodName" className="NC-formlabel" />
                          <input
                            className="form-control NC-fullWidth"
                            type="text"
                            // value={productName}
                            id="prodName"
                            hidden
                          />

                          {/* <div className="col-md-12 col-xs-12 col-sm-12 NC-formsubmit">
                      <button
                        className="homeBtn"
                        htmlType="submit"
                        href=""
                      >
                        Submit{" "}
                        <img
                          className="iconCheckout"
                          src="https://res.cloudinary.com/djoztpm50/image/upload/v1551181856/Native%20Circle/path.svg"
                        />
                      </button>
                    </div> */}
                        </div>
                      </form>
                    </Modal>

                  </div>
                </div>
                :
                <div />
            }
          </div>

          <div className="col-sm-12">
            <h3 className="forfarmerHead">
              Workshops & Events
            </h3>
            <div className="col-sm-6 farmerPoints">
              <div className="col-sm-12">
                <div className="row">
                  <div className="col-sm-12 farmerbtnMain">
                    {/* <button className="downloadfarmer" href="">Download the App <img className="iconCheckout" src="https://res.cloudinary.com/djoztpm50/image/upload/v1551253843/Native%20Circle/path-down.png"/></button> */}

                    <a
                      href="https://www.youtube.com/channel/UCY7_eKI3mv_mVCnhFFpWXEA"
                      target="_blank"
                    >

                      <button className="watchVideoBanner">
                        Watch videos{" "}
                        <img
                          className="iconCheckout"
                          src="https://res.cloudinary.com/djoztpm50/image/upload/v1551253847/Native%20Circle/path-video.png"
                        />
                      </button>

                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* farmers in india starts here */}
        {/* <div className="container pad0">
          <div className="col-sm-12 pad0 connectFarmerMain">
            <h5 className="connectFarmerHead">
              Native Circle for{" "}
              <span className="connectFarmerHeadSpan">Farmers in India</span>
            </h5>
            <div className="row">
              <div className="col-sm-6">
                <div className="row imageReps">
                  <div className="col-sm-6">
                    <img src="https://res.cloudinary.com/djoztpm50/image/upload/v1565279509/spash-min_snqpya.png" />
                  </div>
                  <div className="col-sm-6">
                    <img src="https://res.cloudinary.com/djoztpm50/image/upload/v1551256917/Native%20Circle/details-mup.png" />
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <h5 className="farmerTransparent">How would you benefit?</h5>
                <p className="farmerTransparentInfo farmerTransparentInfoCus">
                  Empowering farmers through digital connections to realise an
                  economically viable organic society . Launching Native Circle
                  App to reach out to the farming community and empowering it
                  with the right information, guidance and outlets.
                </p>
                <div className="media">
                  <div className="media-left">
                    <img
                      src="https://res.cloudinary.com/djoztpm50/image/upload/v1565276472/group-28-min_twhzi9.png"
                      className="media-object"
                    />
                  </div>
                  <div className="media-body">
                    <p className="farmerPointList">
                      Learn what to grow, when to grow & how to grow
                    </p>
                  </div>
                </div>

                <div className="media mediaSec">
                  <div className="media-left">
                    <img
                      src="https://res.cloudinary.com/djoztpm50/image/upload/v1565276472/group-28-min_twhzi9.png"
                      className="media-object"
                    />
                  </div>
                  <div className="media-body">
                    <p className="farmerPointList">
                      Earn profits by growing and selling organic
                    </p>
                  </div>
                </div>


                <a
                  href="https://www.youtube.com/channel/UCY7_eKI3mv_mVCnhFFpWXEA"
                  target="_blank"
                >
                  <div className="watchVideoBtnCenter">
                    <button className="otherButtons watchvid">
                      Watch videos{" "}
                      <img
                        className="watchVideoIcon"
                        src="https://res.cloudinary.com/djoztpm50/image/upload/v1551866451/Native%20Circle/path_1.svg"
                      />
                    </button></div>
                </a>
              </div>
            </div>
          </div>
        </div> */}
        {/* farmers in india ends here */}


        {/* knowledge bank starts here */}
        {/* <div className="col-sm-12 pad0 HowitworksMainDiv">
          <div className="container">
            <div className="col-sm-12 pad0">
              <h5 className="connectFarmerHead">
                Native Circle  <span className="connectFarmerHeadSpan">Knowledge Bank</span>
              </h5>

              <div className="row">
                <div className="col-sm-4">
                  <FormerSuccess
                    videoid="_D8bHWicTug"
                    img="https://res.cloudinary.com/djoztpm50/image/upload/v1565172847/1-min-min_hnhj8a.png"
                    heading="Water warrior of India - Ayyappa Masagi"
                    content="Story of Ayyappa Masagi who is fighting for the cause of water conservation and management relentlessly."
                  />
                </div>

                <div className="col-sm-4">
                  <FormerSuccess
                    videoid="972nmxvRjN0"
                    img="https://res.cloudinary.com/djoztpm50/image/upload/v1565172856/2-min-min_bzmaze.png"
                    heading="Dr. Sanjeev Kulkarni of Sumana Sangama"
                    content="Dr. Sanjeev Kulkarni, a gynaecologist by profession is a versatile personality. He runs a school and has a natural farm called Sumana Sangama."
                  />
                </div>

                <div className="col-sm-4">
                  <FormerSuccess
                    videoid="SoqVI6GdZdI"
                    img="https://res.cloudinary.com/djoztpm50/image/upload/v1565172863/3-min-min_bceva1.png"
                    heading="Venkatagiri - the barefoot botanist"
                    content="Venkatagiri is a self made botanist who is working hard to conserve endangered species of rare forest and medicinal plants."
                  />
                </div>
                <div className="col-sm-12 btnMainCheckout">
                  <a
                    href="https://www.youtube.com/channel/UCY7_eKI3mv_mVCnhFFpWXEA"
                    target="_blank"
                  >
                    <button className="otherButtons">
                      Check out for more{" "}
                      <img
                        className="watchVideoIcon"
                        src="https://res.cloudinary.com/djoztpm50/image/upload/v1551866451/Native%20Circle/path_1.svg"
                      />
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/* knowledge bank ends here */}

        <div className="col-sm-12 pad0 workshopDiv" >
          <div className="container">
            <div className="col-sm-12 pad0">
              <h5 className="connectFarmerHead">
                Native Circle  <span className="connectFarmerHeadSpan">Workshops</span>
              </h5>
              <p className="workshopDesc"> Native Circle workshops have been quite popular among natural farming entusiasts. Native Circle started reaching out to the people through its workshops. Usually a successful natural farmer is choosen in a particular cluster/region of any part of Karnataka and farmers and interested people are invited to participate. While the successful farmer/s share their methods of cultivation, disease and pest management, maintaining soil fertility and such issues, the participants interact with them to get the right knowledge and direction. Till now, more than 15 such workshops have been conducted all across karnataka where various topics of natural farming are covered.</p>
              <Tabs className="workshopTab" defaultActiveKey="1" onChange={this.callback}>

                <TabPane tab="Upcoming Events" key="1">
                  <div className="row">
                    {upcomingWorkshop.length != 0 ?
                      upcomingWorkshop.map(e => {
                        return (
                          <div className="col-sm-4">
                            <Workshop
                              img={e.node.acf.workshop_image == null
                                ? "http://dashboard.nativecircle.in/wp-content/uploads/2019/08/sk2.jpg"
                                : e.node.acf.workshop_image.source_url}
                              date={e.node.displayDate}
                              location={e.node.acf.location}
                              title={e.node.title}
                              description={e.node.acf.description}
                              no_of_days={e.node.acf.no_of_days}
                              workshop_url={e.node.acf.workshop_url == null ? "https://medium.com/native-circle/save-the-seeds-7b2249cc2935" : e.node.acf.workshop_url}
                            />
                          </div>
                        )
                      }
                      ) :
                      <div className="noEvents">Currently there are no events. </div>
                    }

                  </div>
                  {upcomingWorkshop.length != 0 &&
                    <div className="col-sm-12 btnMainCheckout">
                      <Link
                        to=""
                      >
                        <button className="otherButtons">
                          View All{" "}
                          <img
                            className="watchVideoIcon"
                            src="https://res.cloudinary.com/djoztpm50/image/upload/v1551866451/Native%20Circle/path_1.svg"
                          />
                        </button>
                      </Link>
                    </div>

                  }
                </TabPane>
                <TabPane tab="Past Events" key="2">
                  <div className="row">
                    {pastWorkshop.map(e => {
                      return (
                        <div className="col-sm-4">
                          <Workshop
                            img={e.node.acf.workshop_image == null
                              ? "http://dashboard.nativecircle.in/wp-content/uploads/2019/08/sk2.jpg"
                              : e.node.acf.workshop_image}
                            date={e.node.displayDate}
                            location={e.node.acf.location}
                            title={e.node.title}
                            description={e.node.acf.description}
                            no_of_days={e.node.acf.no_of_days}
                            workshop_url={e.node.acf.workshop_url == null ? "https://medium.com/native-circle/save-the-seeds-7b2249cc2935" : e.node.acf.workshop_url}
                          />
                        </div>
                      )
                    }
                    )
                    }

                  </div>
                  <div className="col-sm-12 btnMainCheckout">
                    <Link
                      to=""
                    >
                      <button className="otherButtons">
                        View All{" "}
                        <img
                          className="watchVideoIcon"
                          src="https://res.cloudinary.com/djoztpm50/image/upload/v1551866451/Native%20Circle/path_1.svg"
                        />
                      </button>
                    </Link>
                  </div>
                </TabPane>

              </Tabs>

            </div>
          </div>

        </div>

        <LetsChat />
      </Layout>
    );
  }
}

export default FarmerMod;
export const pageQuery = graphql`
query nativecircleworkshopInAboutUs {
  allWordpressWpWorkshop {
    edges {
      node {
        title
       acf {
          no_of_days
          event_date
          description
          location
          workshop_image {
            source_url
          }
          workshop_url
        }
      }
    }
  }
}
`;
